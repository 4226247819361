.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 4rem 0.2rem;
  background-color: var(--background-color);
  height: 8vh;
}

.header-left {
  display: flex;
  align-items: center;
}

.nav {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: left;
  align-items: top;
  margin: 0;
  padding-top: 0.3rem;
  padding-left: 0;
}

.nav-item {
  position: relative;
  padding: 0 1rem;
}

.nav-item a, 
.nav-item button {
  text-decoration: none;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--text-color);
}

.nav-item .underline-container {
  padding: 0.2rem 0;
  width: 100%;
}

.nav-item .underline {
  position: relative;
  display: block;
  width: 0;
  height: 0.05rem;
  background: var(--text-color);
  transition: width 0.3s;
}

.nav-item:hover .underline {
  width: 100%;
}

.nav-item a:hover, 
.nav-item button:hover {
  color: var(--secondary-text-color);
  background-color: transparent;
  transition: color 0.3s;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  background-color: var(--background-color);
  border: 1px solid var(--text-color);
  border-radius: 0.5rem;
  min-width: 5rem;
  z-index: 20;
  margin-left: -0.5rem;
  padding-right: 1rem;
}

.nav-item:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dropdown-content a,
.dropdown-content button {
  color: var(--text-color);
  padding: 0.7rem;
  text-decoration: none;
}

.dropdown-content a:hover {
  text-decoration: underline;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.language-dropdown {
  position: relative;
  margin-right: 1rem;
}

.language-dropdown:hover .dropdown-content {
  display: block;
}

.header-right a {
  margin-right: 1rem;
  text-decoration: none;
  color: var(--text-color);
}

.header-right a:hover {
  color: var(--secondary-background-color);
  text-decoration: underline;
}

.dropdown-content button:hover {
  text-decoration: underline;
}

.theme-toggle {
  background-color: var(--text-color);
  color: var(--background-color);
  border-radius: 50%;
  border: none;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.1rem;
  border-radius: 0;
}

.hamburger-menu:hover {
  background-color: transparent
}

.hamburger-menu span {
  width: 100%;
  height: 0.2rem;
  background-color: var(--text-color);
}

.sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 70%;
  height: 100%;
  background-color: var(--background-color);
  transition: left 0.3s ease;
  z-index: 1000;
  padding-block: 2rem;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar .nav-item {
  margin-bottom: 1.5rem;
}

.sidebar .nav-item a,
.sidebar .nav-item button {
  font-size: 1rem;
}

.sidebar .dropdown-content {
  position: static;
  background-color: transparent;
  border: none;
  padding: 1rem;
}

.sidebar .nav-item .underline-container {
  display: none;
}

.sidebar .nav-item .dropdown-content a,
.sidebar .nav-item .dropdown-content button {
  padding: 0.5rem 0;
  font-size: 1rem;
}

.sidebar .theme-toggle {
  background-color: var(--text-color);
  color: var(--background-color);
  border-radius: 50%;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar .logo-apartments-junona {
  margin-block: 2rem;
  max-height: 5rem;
  width: auto;
  max-width: 90%;
  display: flex;
  align-items: center;
}

@media (max-width: 1100px) {
  .header {
    padding-inline: 1rem;
  }

  .nav,
  .header-right {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }

  .dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
