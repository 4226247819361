.loading {
    text-align: center;
    margin-block: 30vh;
}

.error {
    text-align: center;
}

.loading-dots::after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80%, 100% {
    content: '';
  }
}  

.image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 30rem;
    overflow: hidden;
    gap: 1rem;
}

.grid-image {
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
}

.grid-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.small-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
}

.image-overlay-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--background-color-half-transparent);
    color: var(--text-color);
    border: none;
    padding: 0;
    cursor: pointer;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 0;
    font-size: 8rem;
}

.image-overlay-button:hover {
    color: var(--background-color);
    background-color: var(--text-color);
}

.main-features-bar {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-block: 1rem;
    margin-right: 40%;
    font-size: 1.1rem;
}

.special-features-bar {
    display: flex;
    gap: 2rem;
}

.address {
    font-size: 1.5rem;
}

.section-header {
    margin-block: 2rem;
}

.section-tag {
    display: inline-block;
    background-color: var(--text-color);
    color: var(--background-color);
    padding-block: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
    border-radius: 0 1rem 0 0;
    margin-bottom: 0;
}

.section-tag-line {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 0.1rem solid var(--text-color);
    margin: 0;
    position: relative;
    top: 0;
}

.item-content {
    display: grid;
    grid-template-columns: 60% auto;
    gap: 3rem;
}

.highlighted-list {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
}

.highlighted-list li {
    position: relative;
    margin-bottom: 1rem;
    padding: 1rem;
    padding-left: 2rem;
    background-color: var(--background-color);
    border: 1px solid var(--text-color);
}

.highlighted-list li::before {
    content: "‣";
    position: absolute;
    left: 1rem;
}

.item-features {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-block: 1rem;
}

.item-features .feature-tile {
    padding: 1rem;
    border: 1px solid var(--text-color);
    border-radius: 20px;
}

.floating-window-container {
    margin-top: -6rem;
    height: 100% + 6rem;
}

.floating-window {
    position: absolute;
    width: 20%;
    right: 10%;
    background-color: var(--text-color);
    padding-block: 1rem;
    padding-inline: 3rem;
    border-radius: 1rem;
    height: min-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
}

.price-section {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    color: var(--background-color);
    flex-wrap: wrap;
    gap: 1rem;
}

.price-section .price {
    font-weight: bold;
}

.provision {
    color: var(--background-color);
}

.floating-window .action-button {
    width: 100%;
    padding-inline: 0;
}

.floating-window .status-message {
    color: var(--background-color);
}

.divider {
    color: var(--background-color);
}

.two-column-container {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 60% auto;
    height: min-content;
}

.text-column {
    height: 100%;
    padding-right: 5rem;
}

.input-section {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin: 1rem 0;
    width: 100%;
}

.input-field {
    border: 1px solid var(--background-color);
    border-radius: 1rem;
    padding: 0.8rem;
    color: var(--background-color);
    background-color: var(--secondary-text-color);
}

.call-to-action {
    text-align: center;
}

.call-to-action .divider {
    color: var(--text-color);
}

.call-to-action .action-button {
    width: 100%;
    margin-right: 2rem;
    padding-inline: 0;
}

.carousel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.carousel-content {
    position: relative;
    width: 90%;
    height: 90%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carousel-image {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.carousel-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 2rem;
    cursor: pointer;
}

.carousel-close-button:hover {
    color: var(--secondary-text-color);
    background-color: transparent;
}

.carousel-nav {
    position: absolute;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 3rem;
    cursor: pointer;
}

.carousel-nav:hover {
    color: var(--secondary-text-color);
    background-color: transparent;
}

.carousel-nav.prev {
    left: 2vw;
}

.carousel-nav.next {
    right: 2vw;
}

@media (max-width: 900px) {
    .image-grid {
        height: 20rem;
    }

    .image-overlay-button {
        font-size: 5rem;
    }

    .main-features-bar {
        margin-right: 0%;
        font-size: 1rem;
        flex-direction: column;
        gap: 2rem;
    }

    .special-features-bar {
        gap: 1rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .address {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    }

    .item-content {
        margin-top: -1rem;
        grid-template-columns: 100%;
    }

    .two-column-container {
        grid-template-columns: 100%;
        grid-template-rows: auto 70vh;
        gap: 1rem;
    }

    .text-column {
        padding-inline: 0;
    }

    .floating-window-container,
    .floating-window {
        display: none;
    }
}