.collaboration-header {
    text-align: center;
}

.collaboration-options {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 10rem;
}

.options-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-block: 3rem;
}
  
.option-button {
    color: var(--background-color);
    background-color: var(--text-color);
    border: none;
    border-radius: 30px;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    cursor: pointer;
    text-align: center;
}
  
.option-button strong {
    font-weight: bold;
}
  
.option-button:hover {
    color: var(--secondary-background-color);
    background-color: var(--secondary-text-color);
}

.collaboration-option-explained {
    background-color: var(--text-color);
    color: var(--background-color);
    padding: 3rem;
    border-radius: 30px;
    margin-block: 4rem;
    display: flex;
    flex-direction: column;
}

.collaboration-benefits ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    margin-block: 1rem;
}
  
.collaboration-benefits li {
    font-size: 1.1rem;
    margin-bottom: 0rem;
    margin-right: 1rem;
}


@media (max-width: 900px) {
    .collaboration-option-explained {
        padding-inline: 2rem;
    }
}
