.home-two-column-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 5rem;
}
  
.home-left-column-container {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.property-images {
  padding-block: 3rem;
  max-width: 45%;
  display: grid;
  grid-template-columns: 6fr 5fr;
  grid-template-rows: 40rem;
  gap: 1rem;
}

.left-property-images,
.right-property-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.left-property-images {
  align-items: flex-end;
}

.right-property-images {
  margin-top: 20%;
}

.left-property-image,
.right-property-image {
  object-fit: cover;
  border-radius: 10px;
}

.left-property-image:nth-child(1),
.right-property-image:nth-child(1) {
  height: 35%;
}

.left-property-image:nth-child(1) {
  width: 80%;
}

.right-property-image:nth-child(1) {
  width: 100%;
}

.left-property-image:nth-child(2) {
  height: 35%;
  width: 100%;
}

.left-property-image:nth-child(3) {
  height: 25%;
  width: 80%;
}

.right-property-image:nth-child(2) {
  height: 50%;
  width: 75%;
}

.services-bullets ul {
  padding-block: 2rem;
}

.services-bullets ul li:nth-child(2n + 1) {
  margin-left: 10rem; 
  margin-block: 2rem;
}

.services-bullets ul li {
  font-size: 1.5rem;
}

.new-developments-images {
  max-width: 40%;
  padding-block: 2rem;
}

.kopaonik-image {
  width: 100%;
  height: 12rem;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  .home-two-column-container {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .home-left-column-container {
    text-align: center;
    max-width: 100%;
    align-items: center;
  }

  .property-images {
    max-width: 100%;
    grid-template-rows: 30rem;
  }

  .services-bullets ul li:nth-child(2n + 1) {
    margin-left: 5rem; 
    margin-block: 1rem;
  }

  .services-bullets ul li {
    font-size: 1.2rem;
  }

  .new-developments-images {
    max-width: 100%;
  }
}
