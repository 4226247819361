.contact-page {
  display: grid;
  grid-template-columns: 50% 50%;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 5rem;
  gap: 1.5rem;
  width: 100%;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
}


.item-data {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-data:hover {
  color: var(--secondary-text-color);
}

.item-icon {
  font-size: 1.2rem; 
  padding: 0.8rem;
  border-radius: 50%;
  background-color: var(--text-color);
  color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}

.item-icon:hover {
  background-color: var(--secondary-text-color);
}

.map {
  width: 100%;
}

.map iframe {
  border: 0;
  width: 100%;
  height: 30rem;
  border-radius: 1rem;
}

.contact-us-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -8rem;
  padding-inline: 10%;
}

.logo-container {
  width: 100%;
  height: 25rem;
  background-color: var(--text-color);
  border-radius: 50% 50% 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.logo-image {
  display: flex;
  object-fit: contain;
  height: 70%;
  width: auto;
  position: absolute;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--background-color);
  margin-top: 2rem;
  border-radius: 10px;
  width: 100%;
  height: min-content;
}

.form-container h2 {
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.contact-form {
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: min-content;
}

.contact-input-field,
.contact-select-field,
.contact-text-area {
  padding: 1rem;
  border: none;
  border-radius: 30px;
  border: 2px solid white;
  background-color: transparent;
  color: var(--text-color);
}

.contact-text-area {
  height: 20rem;
  resize: none;
}

.form-container .action-button {
  width: auto;
  margin-bottom: 2rem;
}

.status-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 1rem;
}

@media (max-width: 900px) {
  .contact-page {
    display: block;
  }

  .logo-container {
    display: none;
  }

  .contact-us-form {
    padding: 0;
    margin-top: -4rem;
  }
}