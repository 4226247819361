.logo-inline {
    height: 3rem;
}
  
.logo-vertical {
    height: 100%;
}

.logo-apartments-junona {
    height: 1.5rem;
}
