.page-header {
  padding-block: 2rem;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--background-color);
}

.page-title {
  margin-bottom: 0;
  color: var(--text-color);
}

.page-subtitle {
  margin-left: 0.1rem;
}
