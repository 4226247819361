.offer-card {
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 2rem auto;
  height: 20rem;
  cursor: pointer;
  border: 1px solid var(--text-color);
}

.offer-card:hover {
  color: var(--background-color);
  background-color: var(--text-color);
  transition: 0.5s ease;
}

.offer-card-image {  
  width: 40%;
  max-width: 30rem;
  height: 100%;
}

.offer-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer-card-details {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.offer-card-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem
}

.price-container {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}


@media (max-width: 900px) {
  .offer-card {
    flex-direction: column; /* Stack image and details vertically */
    height: auto; /* Remove fixed height */
  }

  .offer-card-image {
    width: 100%; /* Make the image take full width */
    height: 15rem; /* Adjust the height of the image for mobile */
    max-width: 100%;
  }

  .offer-card-details {
    padding: 1rem;
  }
}