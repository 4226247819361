:root {
  /* General Colors */
  --text-color: rgb(29, 29, 27);
  --secondary-text-color: rgb(35, 35, 33);
  --background-color: rgb(209, 183, 46);
  --background-color-half-transparent: rgba(209, 183, 46, 0.5);
  --secondary-background-color: rgb(229, 207, 115);
  --white: rgb(255, 255, 255);
  
  /* Dark Mode Colors */
  --dark-text-color: rgb(209, 183, 46);
  --dark-secondary-text-color: rgb(229, 207, 115);
  --dark-background-color: rgb(29, 29, 27);
  --dark-background-color-half-transparent: rgba(29, 29, 27, 0.5);
  --dark-secondary-background-color: rgb(35, 35, 33);
  
  /* Light Mode Colors */
  --light-text-color: rgb(29, 29, 27);
  --light-secondary-text-color: rgb(35, 35, 33);
  --light-background-color: rgb(209, 183, 46);
  --light-background-color-half-transparent: rgba(209, 183, 46, 0.5);
  --light-secondary-background-color: rgb(229, 207, 115);
}

/* Scrollbar Customization */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 10px;
  border: 2px solid var(--background-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-text-color);
  cursor: pointer;
}

/* Global Styles */
body {
  font-family: 'Urbanist', sans-serif;
  margin: 0;
  padding: 0;
  color: var(--text-color);
  background-color: var(--secondary-background-color);
}

.container {
  padding-inline: 10%;
}

.darker-background {
  background-color: var(--background-color);
}

/* Button Styles */
button {
  font-family: 'Urbanist', sans-serif;
  border-radius: 2rem;
  color: var(--background-color);
  background-color: var(--text-color);
  border: none;
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: var(--secondary-text-color);
  transition: background-color 0.2s ease-in-out;
}

/* Typography */
h1 {
  font-size: 2.5rem;
  font-weight: 1000;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

h4, h5, h6 {
  font-weight: 400;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 1.25rem;
  margin-top: 0.8rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Links */
a {
  text-decoration: none;
  color: var(--text-color);
  background-color: transparent;
}

/* Lists */
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding-block: 0.5rem;
}

/* Action Buttons */
.action-button {
  border: none;
  border-radius: 30px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  cursor: pointer;
  margin-block: 1rem;
  width: max-content;
  background-color: var(--text-color); 
  color: var(--background-color);
  z-index: 35;
  display: block;
}

.action-button:hover {
  background-color: var(--secondary-text-color); 
  color: var(--secondary-background-color);
  transition: background-color 0.2s, color 0.2s;
}

/* Inverse Color Scheme */
.inverse-colors {
  background-color: var(--background-color);
  color: var(--text-color); 
}

.inverse-colors:hover {
  background-color: var(--secondary-background-color); 
  color: var(--secondary-text-color); 
  transition: background-color 0.2s, color 0.2s;
}

@media (max-width: 900px) {
  .container {
    padding-inline: 5%;
  }
}