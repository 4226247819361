.filters-bar-container {
  padding-block: 2rem;
}

.filters-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2rem;
  background-color: var(--text-color);
}
  
.filter-item {
  background-color: var(--text-color);
  border: 1px solid var(--background-color);
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem;
  margin: 0.1rem;
  outline: none;
  cursor: pointer;
  border-radius: 2rem;
  margin-right: 1rem;
  position: relative;
  appearance: none;
  display: inline-block;
  width: 100%;
  padding-right: 2rem;
}

.filter-item::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.8rem;
  margin-left: 1rem;
  color: var(--background-color);
}

.filter-item-active {
  background-color: var(--secondary-text-color);
}

.filters-bar .search-button {
  border: none;
  padding: 1rem 2rem;
  margin: 0.2rem;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.dropdown-content-filter {
  display: none;
  position: absolute;
  padding: 0.8rem;
  padding-right: 1.5rem;
  background-color: var(--secondary-text-color);
  border: 1px solid var(--background-color);
  border-radius: 2rem;
  top: 100%;
  left: 0;
  min-width: 100%;
  width: max-content;
  box-sizing: border-box;
  z-index: 100;
  color: var(--background-color);
}

.filter-dropdown:hover .dropdown-content-filter,
.filter-dropdown .filter-item-active + .dropdown-content-filter {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.min-max-inputs {
  display: flex;
  align-items: center;
}

.min-max-input {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.filter-input {
  padding: 0.5rem;
  border-radius: 2rem;
  width: 5rem;
  text-align: center;
  border: 1px solid var(--background-color);
  background-color: var(--text-color);
}

.filter-input::placeholder {
  opacity: 0.6;
}

.filter-dropdown {
  position: relative;
}

.filter-toggle-button {
  display: none;
}

@media (max-width: 900px) {
  .filters-bar-invisible {
    display: none;
  }

  .filters-bar {
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 55vh;
    padding: 2rem;
  }

  .filter-item {
    width: 100%;
  }

  .filter-toggle-button {
    background-color: var(--text-color);
    color: var(--background-color);
    border: none;
    padding: 1rem 2rem;
    border-radius: 2rem;
    cursor: pointer;
    display: block;
    margin: 1rem 0;
    width: 100%;
    text-align: center;
  }

  .filter-toggle-button:focus {
    outline: none;
  }
}

