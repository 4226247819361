.our-story {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 2rem;
    margin-top: 4rem;
}

.story-image {
    display: flex;
    align-items: center;
}

.story-image img {
    width: 100%;
    height: min-content;
    border-radius: 10px;
}

.our-vision {
    margin-top: 20rem;
    margin-bottom: 28rem;
    width: 40%;
}

.vision-item {
    position: absolute;
    display: flex;
    align-items: center;
    margin: 2rem 0;
    width: 30%;
}
  
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--text-color);
    border-radius: 50%;
    padding: 1rem;
    margin-right: 1.5rem;
    width: 5rem;
    height: 5rem;
    font-size: 5rem;
}

.vision-text {
    width: 100%;
}

.trust {
    margin-top: -28rem;
    margin-left: 25%;
}

.empathy {
    margin-top: -13rem;
    margin-left: 50%;
}

.professionalism {
    margin-top: 5rem;
    margin-left: 25%;
}

.our-services {
    text-align: center;
}
  
.services-container {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
}
  
.service-card {
    border-radius: 10px;
    width: 100%;
    background-color: var(--background-color);
    text-align: left;
    transition: transform 0.3s ease-in-out;
}

.service-card h3 {
    margin-top: 0;
    background-color: var(--text-color);
    color: var(--background-color);
    padding: 1rem;
    border-radius: 10px 10px 0 0;
    margin-bottom: 1rem;
}

.service-card p {
    padding: 1rem;
}

.why-us {
    margin-block: 10rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-reason {
    color: var(--secondary-text-color);
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 2rem;
}

.sub-reason {
    font-size: 2rem;
    margin-bottom: 3rem;
}

@media (max-width: 1300px) {
    .our-story {
        display: flex;
        flex-direction: column-reverse;
    }
  
    .our-vision {
        display: flex;
        flex-direction: column;
        margin-block: 1rem;
        width: 100%;
    }

    .vision-item {
        position: relative;
        margin: 2rem 0;
        width: 100%;
    }

    .icon-container {
        width: 3rem;
        height: 3rem;
    }

    .vision-text {
        width: 100%;
    }

    .empathy {
        margin: 0;
        width: 100%;
    }

    .trust {
        margin: 0;
        width: 100%;
    }

    .professionalism {
        margin: 0;
        width: 100%;
    }

    .services-container {
        display: flex;
        flex-direction: column;
    }
}
