.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 5rem;
  color: var(--dark-text-color);
  background-color: var(--dark-background-color);
  margin-top: 5rem;
}

.footer-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.footer-section {
  padding: 1rem;
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.footer-socials {
  display: flex;
  gap: 1.2rem;
  margin-bottom: 1rem;
}

.footer-socials a {
  color: var(--dark-text-color);
  background-color:  var(--dark-background-color);
}

.footer-social-link {
  font-size: 1.2rem;
  border-radius: 50%;
  border: 1px solid var(--dark-text-color);
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s ease;
}

.footer-social-link:hover {
  background-color: var(--dark-secondary-text-color);
  color:  var(--dark-secondary-background-color);
}

.footer-questions {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer-questions .action-button {
  background-color: var(--dark-text-color);
  color:  var(--dark-background-color);
}

.footer-questions .action-button:hover {
  background-color: var(--dark-secondary-text-color);
  color:  var(--dark-secondary-background-color);
}

.footer-column {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

.footer-column h3 {
  margin: 0.8rem 0 1rem;
  font-weight: 400;
}

.footer-column a {
  margin: 2rem 0;
  text-decoration: none;
  transition: text-decoration 0.3s ease;
  color:  var(--dark-text-color);
}

.footer-column a:hover {
  text-decoration: underline;
  color:  var(--dark-secondary-text-color);
}

.footer-link a {
  text-decoration: none;
  font-weight: bold;
  transition: text-decoration 0.3s ease;
}

.footer-link a:hover {
  text-decoration: underline;
}

.footer-middle {
  text-align: center;
}

.footer-right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding-left: 3rem;
}

.footer-copyright {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.footer-line {
  border: 0;
  height: 1px;
  background: var(--dark-text-color);
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  .footer {
    padding: 2rem 0.5rem;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-questions,
  .footer-column {
    text-align: center;
    align-items: center;
  }

  .footer-right {
    padding: 1rem;
  }
}
