.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 92vh;
  overflow: hidden;
}

.background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.billboard img,
.lights img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.background-wide,
.billboard-wide,
.lights-wide {
  display: block;
}

.background-portrait,
.billboard-portrait,
.lights-portrait {
  display: none;
}

.hero-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 80vh;
  height: 100%;
  padding-top: 18vh;
}

.hero-content h1 {
  color: var(--light-text-color);
  margin: 0.1vh;
  font-size: 5vh;
}

.hero-content h3 {
  color: var(--light-text-color);
  margin-top: 0.1vh;
  font-size: 2vh;
}

.hero-content a {
  font-size: 2.5vh;
  padding-block: 2vh;
  padding-inline: 8vh;
  margin-block: 4vh;
}

.hero-content .action-button {
  background-color: var(--dark-background-color);
  color: var(--dark-text-color);
}

.hero-content .action-button:hover {
  background-color: var(--light-secondary-background-color);
  color: var(--light-text-color);
}

.lights img {
  z-index: 15;
}

@media (max-width: 900px) and (aspect-ratio < 1/1) {
  .background-wide,
  .billboard-wide,
  .lights-wide {
    display: none;
  }

  .background-portrait,
  .billboard-portrait,
  .lights-portrait {
    display: block;
  }

  .hero-content {
    padding-top: 18vh;
    max-width: 32vh;
  }

  .hero-content h1 {
    font-size: 3.5vh;
  }
  
  .hero-content h3 {
    font-size: 1.8vh;
  }

  .hero-content a {
    font-size: 1.8vh;
    padding-inline: 4vh;
  }
}
